import cnBind from "classnames/bind";
import type { MenuItem } from "primereact/menuitem";

import { BreadCrumb } from "@/components/BreadCrumb";
import { PageLayout } from "@/layouts/PageLayout";

import styles from "./Policy.module.scss";

const cx = cnBind.bind(styles);
export const Policy = () => {
    const breadcrumbs: MenuItem[] = [{ label: "Политика конфиденциальности" }];

    return (
        <PageLayout>
            <BreadCrumb model={breadcrumbs} />
            <div className={cx("policy")}>
                <div className={cx("wrapper", "container")}>
                    <h2>Политика конфиденциальности</h2>

                    <div className={cx("content")}>
                        <div className={cx("section")}>
                            <h4 className={cx("title")}>1. Общие положения</h4>
                            <div className={cx("list")}>
                                <div className={cx("list-item")}>
                                    <span>1.1</span>
                                    <span>
                                        В соответствии с п.2 ст. 437 Гражданского кодекса Российской Федерации данный
                                        документ адресован любому лицу (именуемый далее по тексту «Заказчик»), является
                                        официальным и публичным предложением ИП Букиной Екатерины Евгеньевны, (именуемой
                                        далее по тексту «Исполнитель»), заключить договор на указанных ниже условиях.
                                    </span>
                                </div>

                                <div className={cx("list-item")}>
                                    <span>1.2</span>
                                    <span>
                                        Полным и безоговорочным акцептом настоящей публичной оферты является
                                        осуществление Заказчиком выражения своего согласия с условиями оферты на сайте,
                                        либо проведение оплаты предложенных услуг в порядке, определенном в настоящем
                                        Предложении, а также выполнение любых иных фактических действий по бронированию
                                        залов/фотостудии POLEMOSKVA в силу п.3 ст. 438 ГК РФ.
                                    </span>
                                </div>
                                <div className={cx("list-item")}>
                                    <span>1.3</span>
                                    <span>
                                        Акцепт оферты означает, что Заказчик согласен со всеми положениями настоящего
                                        Предложения, и равносилен заключению Договора об оказании услуг по
                                        предоставлению во временное пользование съемочных павильонов, оборудования,
                                        находящихся на базе фотостудии POLEMOSKVA.
                                    </span>
                                </div>
                                <div className={cx("list-item")}>
                                    <span>1.4</span>
                                    <span>
                                        Перечень оборудования и павильонов, дата и время их использования, способ
                                        внесения предоплаты выбираются Заказчиком самостоятельно https://polemoskva.ru
                                        и/или по телефону путем оформления заявки-заказа с указанием названия павильона
                                        («POLE ZRENIYA», «DUHOVNOE POLE», «FIZICHESKOE POLE») даты съемки, далее по
                                        тексту «Предоплата».
                                    </span>
                                </div>
                            </div>
                            <span className={cx("text")}>
                                При оформлении заявки-предоплаты на сайте или по телефону Заказчик должен подтвердить
                                данные своей Брони для внесения денежных средств в качестве предоплаты.
                            </span>
                        </div>
                        <div className={cx("section")}>
                            <h4 className={cx("title")}>2. Предмет Договора</h4>
                            <div className={cx("list")}>
                                <div className={cx("list-item")}>
                                    <span>2.1</span>
                                    <span>
                                        Исполнитель обязуется по заявке Заказчика оказывать услуги по предоставлению в
                                        аренду помещения Фотостудии, расположенной по адресу 107023 г. Москва, ул.
                                        Электрозаводская, дом 21, а также Фотооборудования (далее – «Услуги»), а
                                        Заказчик обязуется оплачивать такие Услуги.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={cx("section")}>
                            <h4 className={cx("title")}>3. Ответственность сторон</h4>
                            <div className={cx("list")}>
                                <div className={cx("list-item")}>
                                    <span>3.1</span>
                                    <div className={cx("list-ul")}>
                                        Исполнитель вправе отказать в оказании услуг следующим лицам:
                                        <ul>
                                            <li>
                                                <span>
                                                    находящимся в состоянии алкогольного, токсического или
                                                    наркотического опьянения;
                                                </span>
                                            </li>
                                            <li>
                                                <span>в грязной/пачкающей одежде;</span>
                                            </li>
                                            <li>
                                                <span>
                                                    с животными (если их присутствие не было согласовано заранее при
                                                    бронировании съемочного павильона);
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    агрессивно ведущим себя по отношению к другим посетителям или
                                                    персоналу Фотостудии;
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    препятствующим проведению фото/видео съемок в соседних павильонах
                                                    либо нарушающие общественный порядок;
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={cx("list-item")}>
                                    <span>3.2</span>
                                    <span>
                                        Исполнитель не несет ответственность за качество проведенной Заказчиком
                                        самостоятельной видео/фото съемки и уведомляет настоящим Заказчика, что не может
                                        гарантировать возможность записи «чистого звука». Помещения Исполнителя не
                                        оснащены звукоизоляцией.
                                    </span>
                                </div>
                                <div className={cx("list-item")}>
                                    <span>3.3</span>
                                    <span>
                                        При повреждении циклорамы**** (глубокие вмятины, проломы и другие повреждения
                                        градиента и рабочего покрытия данной конструкции, требующие профессиональных
                                        работ по восстановлению поверхности/шпатлевке/покраске) в любом из съемочных
                                        павильонов с Заказчика взимается штраф в размере 30 000 рублей (тридцать тысяч
                                        рублей); ****Циклорама – конструкция, имеющая плавный переход из вертикальной
                                        плоскости в горизонтальную и предназначенная для проведения на ней фото и видео
                                        съемок.
                                    </span>
                                </div>
                                <div className={cx("list-item")}>
                                    <span>3.4</span>
                                    <span>
                                        При хождении по градиенту Циклорамы и оставлении видимых следов данного действия
                                        – вмятин, грязи, пятен или луж с Заказчика взимается штраф в размере 3000 рублей
                                        (трех тысяч рублей).
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
